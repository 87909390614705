import * as React from "react";
import { Link } from "gatsby";
import { Image } from "@common";
import logo from "@images/logo.png";

const NotFoundPage = () => {
  return (
    <main
      style={{
        fontFamily: "Bai Jamjuree, sans-serif",
        height: "100vh",
        position: "relative",
      }}
    >
      <div style={{ padding: "6rem" }}>
        <h1
          style={{
            fontSize: "4.5rem",
            lineHeight: 1,
            marginTop: 0,
            marginBottom: "4rem",
            maxWidth: 350,
          }}
        >
          Ups!
        </h1>
        <p
          style={{ fontSize: "1.5rem", lineHeight: "2rem" }}
          className="font-light"
        >
          Wygląda na to, że podstrona której szukasz nie istnieje
        </p>
        <div style={{ marginTop: "4rem" }}>
          <Link
            className="text-accent1"
            style={{ textDecoration: "none" }}
            to="/"
          >
            Kliknij tutaj aby powrócić do strony głównej
          </Link>
          .
        </div>
      </div>
      <div
        style={{
          contentVisibility: "auto",
          backgroundColor: "var(--main)",
          textAlign: "center",
          height: "6rem",
          width: "100%",
        }}
      >
        <div style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
          WYCENA NIERUCHOMOŚCI
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 30,
          }}
        >
          <Image align="left" width={230} height={50} source={logo} />
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;

export const Head = () => (
  <title>
    Nie znaleziono strony | Wycena nieruchomości - Magdalena Panasiuk
  </title>
);
